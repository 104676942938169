export default () => {
    const notify = useNotificationsStore();
    const { t } = useI18n();
    const projectStore = useProjectsStore();

    async function deleteProject(project: TProject) {
        try {
            await projectStore.deleteProject(project);
            notify.info(t('messages.projectDeleting'));
            await navigateTo({ name: 'projects-list' });
        } catch {
            notify.error(t('errors.cant_delete'));
        }
    }

    return deleteProject;
};
