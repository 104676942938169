<template>
    <template v-if="status">
        <v-chip
            v-if="mode === 'chip'"
            :color="colorStatus"
            size="small"
            v-bind="$attrs"
            class="object-status justify-center">
            <app-loader-small
                v-if="isBusy"
                class="mr-2"
                :color="colorStatus"></app-loader-small>
            <slot name="label">
                {{ $t(`labels.projectStatus.${status}`) }}
            </slot>
        </v-chip>
        <v-badge
            v-if="mode === 'badge'"
            :color="colorStatus"
            :class="{ live: status !== 'running' && status.endsWith('ing') }"
            v-bind="$attrs">
            <v-tooltip
                activator="parent"
                location="start">
                {{ $t(`labels.projectStatus.${status}`) }}
                <span
                    v-if="message"
                    class="ml-4 opacity-60"
                    >{{ message }}</span
                >
            </v-tooltip>
            <slot />
        </v-badge>
        <div
            v-if="mode === 'text'"
            v-bind="$attrs"
            :class="`text-${colorStatus}`">
            {{ $t(`labels.projectStatus.${status}`) }}
        </div>
    </template>
</template>

<script setup lang="ts">
type TProps = {
    status?: string;
    mode?: 'chip' | 'text' | 'badge';
    message?: string;
};
const props = withDefaults(defineProps<TProps>(), {
    mode: 'chip',
    status: '',
    message: '',
});

const busyStatus = [
    'destroying',
    'deploying',
    'updating',
    'upgrading',
    'stopping',
];

const isBusy = computed(() => {
    return busyStatus.find((x) => x === props.status) !== undefined;
});

const colorStatus = computed(() => {
    switch (props.status) {
        case 'running':
            return 'success';
        case 'queued_deploy':
        case 'deploying':
        case 'updating':
            return 'info';

        case 'error':
        case 'destroying':
        case 'deleting':
            return 'error';
        case 'degraded':
            return 'warning';

        default:
            return '';
    }
});
</script>
<style scoped>
.object-status {
    min-width: 77px !important;
}
</style>
